import React from 'react'
import { graphql, StaticQuery } from 'gatsby';
import CreateAssistantComponent from '../../../components/createassistant/CreateAssistantComponent';

function CreateAssistantTemplate({ cmsData }) {
  
  const data = cmsData?.createassistant?.edges[0]?.node?.frontmatter ?? {}

  return (
      <div>
          <CreateAssistantComponent cmsData={data}/>
      </div>
  )
}

export default function CreateAssistant() {
    return (
      <StaticQuery
        query={graphql`
          query {
            createassistant: allMarkdownRemark(filter: 
              {frontmatter: {templateKey: {eq: "en-createassistant"}}}) {
              edges {
                node {
                  frontmatter {
                    title
                    back
                    next
                    emailSection {
                        mascot {
                            childImageSharp {
                                fluid(maxWidth: 2048, quality: 100) {
                                ...GatsbyImageSharpFluid
                                }
                            }
                        }
                        step
                        title
                        text
                        email
                    }
                    sellingPoints {
                        title
                        step
                        points {
                            icon {
                                childImageSharp {
                                    fluid(maxWidth: 2048, quality: 100) {
                                    ...GatsbyImageSharpFluid
                                    }
                                }
                            }
                            text  
                        }
                    }
                    buildAssistant {
                        title
                        step
                        text
                        mascot {
                            childImageSharp {
                                fluid(maxWidth: 2048, quality: 100) {
                                ...GatsbyImageSharpFluid
                                }
                            }
                        }
                    }
                    areas {
                        title
                        step
                        text
                        categories {
                            iconSelection
                            text
                        }
                    }
                    priorities {
                      title
                      step
                      mascot {
                                childImageSharp {
                                    fluid(maxWidth: 2048, quality: 100) {
                                    ...GatsbyImageSharpFluid
                                    }
                                }
                      }
                      categories {
                        text
                        
                      }

                    }

                    timeSpent {
                      title
                      step
                      mascot {
                                childImageSharp {
                                    fluid(maxWidth: 2048, quality: 100) {
                                    ...GatsbyImageSharpFluid
                                    }
                                }
                      }
                      categories {
                        text
                      }

                    }

                    demo {
                        title
                        text
                        demourl
                    }

                  }
                }
              }
            },
          }
        `}
        render={(data) => <CreateAssistantTemplate cmsData={data} />}
      />
    );
  }
  